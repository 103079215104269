.documents__table {
	thead {
		@include media-breakpoint-up(md) {
			display: table-header-group;
		}
	}

	tbody {
		tr:nth-last-child(2) {
			td {
				@include media-breakpoint-down(sm) {
					flex-direction: column;
				}

				@include media-breakpoint-up(md) {
					border-bottom: 2px solid $gray-200;
					border-left: none;
					border-right: none;
					border-top: 2px solid $gray-200;
					display: table-cell;
				}

				@include media-breakpoint-up(lg) {
					max-width: 400px;
				}

				&::before {
					@include media-breakpoint-up(md) {
						display: none;
					}
				}

				&:first-child {
					@include media-breakpoint-up(md) {
						border-left: 2px solid $gray-200;
						border-bottom-left-radius: 8px;
						border-top-right-radius: 0;
						width: 66px !important;
					}
				}

				&:last-child {
					flex-direction: row;
					justify-content: flex-end;

					@include media-breakpoint-up(md) {
						border-right: 2px solid $gray-200;
						border-bottom-left-radius: 0;
						border-top-right-radius: 8px;
						text-align: right;
					}
				}
			}
		}
	}

	tr {
		&:empty {
			height: 16px;
		}
	}

	.icon {
		vertical-align: middle;
	}

	.icon--pdf {
		height: 32px;
		width: 32px;
	}

	.icon--download {
		height: 18px;
		width: 18px;
	}
}

.documents__file-size {
	color: $text-grey;
	font-size: 12px;
}

.documents__box {
	display: flex;
	flex-direction: column;
	margin-top: $grid-gutter-width;
	padding: $grid-gutter-width;
}

.documents__box-list {
	list-style: none;
	padding: 0;

	li {
		align-items: center;
		box-sizing: content-box;
		display: flex;
		min-height: 48px;
		padding-left: 50px;
		position: relative;

		&::before {
			background-image: url(/assets/img/icons/pdf.svg);
			background-size: contain;
			content: '';
			position: absolute;
			left: 0;
			height: 48px;
			width: 48px;
		}

		a {
			text-decoration: none;
		}

		& + li {
			border-top: 2px solid $color-13;
			margin-top: $grid-gutter-width-half;
			padding-top: $grid-gutter-width-half;
		}
	}
}